import { flexCenter } from '@/layout/styles/classes';
import { largeText, textGradient } from '@/layout/styles/Typography';
import Slider from '@/Slider';
import quote from '@a/icons/quote-left.svg';
import styled from '@emotion/styled';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { Fragment } from 'react';
import { Pagination } from 'swiper';
// eslint-disable-next-line
import 'swiper/css/pagination';
// eslint-disable-next-line
import { SwiperSlide } from 'swiper/react';

const Review = styled.div`
    background-color: ${({ theme }) => theme.colors.lightBlue};
    padding: 64px 32px 106px;
    border-radius: 8px 0px 0px 8px;

    ::before {
        content: url(${quote});
    }

    > p {
        ${largeText};
        margin: 32px 0;
    }

    > div {
        display: flex;
        align-items: center;

        & > div {
            ${flexCenter};
            background-image: ${({ theme }) => theme.colors.gradient};
            width: 60px;
            height: 60px;
            border-radius: 50%;

            > p {
                ${largeText};
                color: ${({ theme }) => theme.colors.white};
                margin: 0;
            }
        }

        > p {
            ${textGradient};
            font-size: 1.25rem;
            margin: 0 0 0 13px;
            font-weight: 600;
            text-transform: capitalize;
        }
    }

    @media (min-width: 1024px) {
        padding: 64px 128px;
        position: relative;
        margin-top: 86px;

        ::before {
            position: absolute;
            left: 128px;
            top: -86px;
            height: 123px;
            width: 140px;
        }
    }
`;

const PaginationDiv = styled.div`
    position: absolute;
    left: 50% !important;
    transform: translateX(-50%);
    bottom: 96px !important;
    z-index: 100;
    width: max-content !important;
    line-height: 10px;
    transition: 0.3s opacity ease-in-out;

    &.swiper-pagination-hidden {
        opacity: 0;
    }

    > span {
        height: 10px !important;
        width: 10px !important;
        margin: 0 16px !important;

        :first-of-type {
            margin-left: 0 !important;
        }

        :last-of-type {
            margin-right: 0 !important;
        }

        &.swiper-pagination-bullet-active {
            background-image: ${({ theme }) => theme.colors.gradient};
        }
    }

    @media (min-width: 1024px) {
        left: calc(100% - 128px - 32px) !important;
        bottom: 239px !important;
        transform: translateX(-100%);
    }
`;

const reviews = [
    {
        name: 'Mokhalad Al-zakr',
        text: 'What a great experience. The dentist takes his time to answer questions and I never feel rushed. The staff is excellent. They are very pleasant and knowledgeable. You will be satisfied after every appointment!',
    },
    {
        name: 'Mirna Hindi',
        text: 'Dr. Luay Simhairy got all 4 of my wisdom teeth out at the same time in 5 minutes, did not feel anything because of how professional the procedure was done. I always come here, the clinic is clean and neat. The staff are all super friendly. Thank you Simhairy Dental for a good dental experience, highly recommended.',
    },
    {
        name: 'Shakiba mominzada',
        text: 'Doctor Simhairy is the best dentist me and my daughter go to him its been two years now i highly recommend him.',
    },
    {
        name: 'Bashar Hyalli',
        text: 'I’ve been coming to this office for a year now, and they never disappoint! The dentist is the best and all the staff are amazing. Highly recommend this place!',
    },
    {
        name: 'Azad Azad',
        text: 'Great Doctor, professional, kind, and listens to his patients clearly. Lovely crew and helpful!',
    },
];

export const TestimonialsSlider = () => {
    type DataTypes = {
        allFile: {
            nodes: {
                childImageSharp: ImageDataLike;
                name: string;
            }[];
        };
    };

    const data: DataTypes = useStaticQuery(graphql`
        query testimonialImages {
            allFile(filter: { relativeDirectory: { eq: "layout/testimonials" } }) {
                nodes {
                    childImageSharp {
                        gatsbyImageData(width: 60, height: 60)
                    }
                    name
                }
            }
        }
    `);

    const finalReviews = reviews.map(review => {
        const image = data.allFile.nodes.filter(
            img => img.name.toLowerCase() === review.name.replace(/ /g, '-').toLowerCase()
        );
        return {
            ...review,
            image:
                image.length > 0 ? (getImage(image[0].childImageSharp) as IGatsbyImageData) : null,
        };
    });

    const sliderSettings = {
        modules: [Pagination],
        pagination: {
            el: '.testimonial-slider-pagination',
            clickable: true,
        },
        spaceBetween: 96,
        slidesPerView: 1,
        autoHeight: true,
    };
    return (
        <Fragment>
            <Slider sliderSettings={sliderSettings}>
                {finalReviews.map((review, i) => (
                    <SwiperSlide key={i}>
                        <Review>
                            <p>{review.text}</p>

                            <div>
                                <div>
                                    {review.image ? (
                                        <GatsbyImage image={review.image} alt={review.name} />
                                    ) : (
                                        <p>{review.name[0]}</p>
                                    )}
                                </div>
                                <p>{review.name}</p>
                            </div>
                        </Review>
                    </SwiperSlide>
                ))}
            </Slider>
            <PaginationDiv className="testimonial-slider-pagination"></PaginationDiv>
        </Fragment>
    );
};
